import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"

const Section = styled.section`
  ${tw`py-c60 2xl:py-c96 my-c96 2xl:my-c120 px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const QuoteWrap = styled.div`
  ${tw`relative md:px-offsetMd`}
`
const Quote = styled.div`
  ${tw`relative text-26 xs:text-32 md:text-60 2xl:text-80 font-ubuntu font-bold px-offsetMd xl:w-4/5`}
`
const StartSymbol = styled.span`
  ${tw`absolute left-8 top-4 md:top-8 font-firacode font-bold transform -translate-y-1/2 -translate-x-1/2`}
  line-height: 1;
`
const EndSymbol = styled.span`
  ${tw`absolute -right-8 md:right-0 bottom-0 md:bottom-12 font-firacode font-bold transform -translate-x-1/2 text-60 md:text-100/100`}
`
const QuoteCredit = styled.p`
  ${tw`font-firacode text-customGrey font-normal text-16 pt-c32 md:pt-c60 px-offsetMd`}
`

const WpAcfQuoteRefBlock = ({ moduleData }) => {
  const blockData = moduleData?.acfRefQuoteBlock
  const sectionAttributes = moduleData?.attributes
  const sectionId = sectionAttributes?.anchor || ""

  return (
    <Section id={sectionId}>
      <QuoteWrap>
        <StartSymbol
          className="text-100/100 md:text-[300px]"
          style={{ color: blockData.symbolsColor || "#E8B365" }}
        >{`/*`}</StartSymbol>
        <EndSymbol style={{ color: blockData.symbolsColor || "#E8B365" }}>
          {`*/`}
          <span className="symbol-code text-customGrey whitespace-nowrap absolute top-1/2 right-0 transform -translate-y-1/2">{`if (love >= project) {return „Zusammenarbeit“}`}</span>
        </EndSymbol>
        <Quote>{parse(blockData.content)}</Quote>
        <QuoteCredit>{blockData.quoteCredit}</QuoteCredit>
      </QuoteWrap>
    </Section>
  )
}

WpAcfQuoteRefBlock.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfQuoteRefBlock
